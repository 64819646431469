import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from "availity-reactstrap-validation";
import { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { ConfirmAlert } from "../../../helpers/ui/alert";
import apiResponse from "../../../services/apiResponse";
import EmployeeSelect from "../Components/EmployeeSelect";
import { AgendaContext } from "../Context/AgendaContext";
import CreateBloc from "./Blocs/CreateBloc";

class IndexView extends Component {
  static contextType = AgendaContext;
  createBloc = new CreateBloc();

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {},
    };
  }

  submitData = async () => {
    const { dataForm } = this.context;
    await this.createBloc.fetchCreate(dataForm);
  };

  handlerOnSubmit = (e, values) => {
    const { dataForm, setDataForm } = this.context;

    e.preventDefault();
    setDataForm({
      ...dataForm,
      ...values,
    });
    this.setState({
      confirm: true,
    });
  };

  preventEnter = (e) => {
    if (e.which === 13) {
      e.preventDefault();
    }
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.createBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.createBloc.createChannel.unsubscribe();
  }

  renderCardForm = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>Tambah Agenda</CardTitle>
          <CardSubtitle className="mb-3">
            Tambah agenda baru dengan mengisi form di bawah ini
          </CardSubtitle>
          <hr />
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
            onKeyPress={this.preventEnter}
          >
            <Row className="mb-3">
              <label htmlFor="agenda_name" className="col-md-3 col-form-label">
                Nama <span className="text-danger">*</span>
              </label>
              <Col md="9">
                <AvField
                  name="agenda_name"
                  placeholder="Nama agenda"
                  type="text"
                  errorMessage="Masukkan nama agenda"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="agenda_name"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label htmlFor="agenda_start" className="col-md-3 col-form-label">
                Mulai <span className="text-danger">*</span>
              </label>
              <Col md="4">
                <AvField
                  name="agenda_start"
                  type="datetime-local"
                  errorMessage="Masukkan waktu mulai"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="agenda_start"
                />
              </Col>
              <label htmlFor="agenda_end" className="col-md-1 col-form-label">
                Selesai <span className="text-danger">*</span>
              </label>
              <Col md="4">
                <AvField
                  name="agenda_end"
                  type="datetime-local"
                  errorMessage="Masukkan waktu selesai"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="agenda_end"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="agenda_description"
                className="col-md-3 col-form-label"
              >
                Keterangan
              </label>
              <Col md="9">
                <AvField
                  name="agenda_description"
                  placeholder="Keterangan agenda..."
                  type="textarea"
                  errorMessage="Masukkan Keterangan"
                  className="form-control"
                  //validate={{ required: { value: true } }}
                  id="agenda_description"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="agenda_location"
                className="col-md-3 col-form-label"
              >
                Lokasi / URL <span className="text-danger">*</span>
              </label>
              <Col md="9">
                <AvField
                  name="agenda_location"
                  placeholder="Lokasi / URL agenda"
                  type="text"
                  errorMessage="Masukkan lokasi agenda"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="agenda_location"
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="agenda_location"
                className="col-md-3 col-form-label"
              >
                Partisipan
              </label>
              <Col md="9">
                <EmployeeSelect />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3"></Col>
              <Col md="9">
                <div className="form-check form-switch mb-3">
                  <AvCheckboxGroup
                    name="checkboxExample"
                    required
                    errorMessage="Agreement harus di centang"
                  >
                    <AvCheckbox
                      label="Saya yakin dan bertanggung jawab atas data ini"
                      value="1"
                    />
                  </AvCheckboxGroup>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3"></Col>
              <Col md="9">
                <Link to="agenda.html">
                  <Button color="success">
                    <i className="uil-arrow-left"></i> Kembali
                  </Button>
                </Link>{" "}
                <Button color="danger" type="submit">
                  <i className="uil-save"></i> Simpan
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  };

  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Col className="col-12">{this.renderCardForm()}</Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
        {this.state.redirect ? <Redirect to="/admin/agenda.html" /> : null}
      </>
    );
  }
}

export default IndexView;
