import { AvCheckbox, AvCheckboxGroup, AvForm, AvField } from "availity-reactstrap-validation";
import React, { Component, Fragment } from 'react';
import { Link, Redirect } from "react-router-dom";
import {
  Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container,
  Row
} from "reactstrap";
import { ConfirmAlert } from '../../../helpers/ui/alert';
import CustomSelect from "../../../helpers/ui/CustomSelect";
import { cancelToken } from "../../../services/adapters/base";
import apiResponse from '../../../services/apiResponse';
import UpdateBloc from './Blocs/UpdateBloc';

class IndexView extends Component {

  updateBloc = new UpdateBloc();

  constructor(props) {
    super(props)
    this.state = {
      dataForm: {},
      detail: {},
    }
  }

  handleServiceChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ["helpdesk_service_name"]: e.value,
      },
    });
  };

  submitData = async () => {
    await this.updateBloc.fetchUpdate(this.state.dataForm)
  }

  handlerOnSubmit = (e, values) => {
    e.preventDefault()
    this.setState({
      dataForm: values,
      confirm: true
    })
  }

  confirmResponse = (response) => {
    let forState = {}
    switch (response) {
      case 'cancel':
        forState = {
          confirm: false
        }
        break;
      case 'confirm':
        forState = {
          loading: true,
          confirm: false
        }
        this.submitData()
        break;
      case 'success':
        forState = {
          success: false,
          redirect: true
        }
        break;
      case 'failed':
        forState = {
          failed: false
        }
        break;
      default:
    }
    this.setState(forState)
  }

  componentDidMount() {
    this.setTokenAPI();

    this.updateBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            detail: response,
          });
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.updateBloc.updateChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break
        default:
          break
      }
    })
  }

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  loadData = async () => {
    const query = {
      search: null,
      limit: null,
      offset: null,
      order: null,
      sort: "asc",
    };

    const queryParams = new URLSearchParams(window.location.search);
    await this.updateBloc.fetchDetail({ ...query, ["helpdesk_service_id"]: queryParams.get("id") }, this.source.token);
  };


  componentWillUnmount() {
    this.updateBloc.detailChannel.unsubscribe();
    this.updateBloc.updateChannel.unsubscribe();
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Ubah Data Master Layanan</CardTitle>
                  <CardSubtitle className="mb-3">
                    Ubah data master layanan dengan mengisi form di bawah ini
                  </CardSubtitle>
                  <hr />
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                  >
                    <AvField
                      name="helpdesk_service_id"
                      type="hidden"
                      validate={{ required: { value: true } }}
                      id="helpdesk_service_id"
                      value={this.state.detail.helpdesk_service_id}
                    />
                    <Row className="mb-3">
                      <label
                        htmlFor="fielCode"
                        className="col-md-3 col-form-label"
                      >
                        Layanan
                      </label>
                      <Col md={9}>
                        <AvField
                          name="helpdesk_service_name"
                          placeholder="Nama Layanan"
                          errorMessage="Masukkan nama layanan"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="helpdesk_service_name"
                          onChange={this.handleServiceChange}
                          value={this.state.detail.helpdesk_service_name}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <div className="form-check form-switch mb-3">
                          <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                            <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                          </AvCheckboxGroup>

                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <Link to="/admin/layanan_portal.html">
                          <Button color="success"><i className="uil-arrow-left"></i> Kembali</Button>
                        </Link>
                        {' '}
                        <Button color="danger" type="submit">
                          <i className="uil-arrow-up-right"></i> Simpan
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"

          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"

          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"

          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"

          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}

          response={this.confirmResponse}
        />
        {this.state.redirect ?
          <Redirect to='/admin/helpdesk_service.html' />
          : null}
      </Fragment>
    )
  }

}

export default IndexView