import moment from 'moment'
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Tooltip } from "reactstrap";
import { accessRole } from "../../../../helpers/access_role";
import { config } from "../../../../helpers/config_global";
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import MKIDatatable from "../../../../modules/mki-datatable/lib";
import { isValidHttpUrl } from '../../../../helpers/utility'
import { cancelToken } from "../../../../services/adapters/base";
import apiResponse from "../../../../services/apiResponse";
import DataTableBloc from "../Blocs/DataTableBloc";

class TableInactiveComponent extends Component {
  dataTableBloc = new DataTableBloc();
  userData = JSON.parse(localStorage.getItem("userdata"));

  source;
  historyTable = "historyAgendaTableInactive";
  defaultOrder = "agenda_start_date";
  defaultSize = 10;
  defaultSort = "desc";
  configDatatable = {
    ...config("datatable"),
    sort: {
      column: this.defaultOrder,
      order: this.defaultSort,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      detailUuid: null,
      activeUuid: null,
      defaultSize: 10,
      rows: [],
      totalRow: 0,
      loadingTable: true,
      filter: sessionStorage.getItem(this.historyTable)
        ? JSON.parse(sessionStorage.getItem(this.historyTable))
        : {
          filter_value: "",
          page_number: 1,
          page_size: this.defaultSize,
          sort_order: {
            column: this.defaultOrder,
            order: this.defaultSort,
          },
        },
    };
  }

  loadData = async () => {
    /* history Datatable */
    sessionStorage.setItem(
      this.historyTable,
      JSON.stringify(this.state.filter)
    );

    /* kirim ke server */
    let offset =
      (this.state.filter.page_number - 1) * this.state.filter.page_size;
    if (this.state.defaultSize !== this.state.filter.page_size) {
      this.setState({
        defaultSize: this.state.filter.page_size,
      });
      offset = 0;
      this.configDatatable.page_number = 1;
    }
    const query = {
      search: this.state.filter.filter_value,
      limit: this.state.filter.page_size,
      offset: offset,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      nip: this.userData.employee_nip,
      status: 0,
    };
    await this.dataTableBloc.fetchRowTable(query, this.source.token);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.activeData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  activeData = async () => {
    await this.dataTableBloc.fetchActive({ agenda_uuid: this.state.activeUuid });
  };

  componentDidMount() {
    this.setTokenAPI();

    this.dataTableBloc.rowtableChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            totalRow: response.total,
            rows: response.result,
            loadingTable: false,
          });
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.dataTableBloc.activeChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState(
            {
              loading: false,
              success: true,
              loadingTable: true,
            },
            function () {
              this.setTokenAPI();
              this.props.handleActiveSuccess();
            }
          );
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.dataTableBloc.rowtableChannel.unsubscribe();
    this.dataTableBloc.activeChannel.unsubscribe();
  }

  columns = [
    {
      key: 'agenda_name',
      text: 'Nama',
      className: 'address',
      sortable: true,
    },
    {
      key: 'agenda_start_date',
      text: 'Mulai',
      className: 'postcode',
      sortable: true,
      cell: (record) => {
        const startDateTime = `${record.agenda_start_date}T${record.agenda_start_time}`
        return (
          <>
            <p>{moment(startDateTime).format('LLLL')}</p>
          </>
        )
      },
      width: '20%',
    },
    {
      key: 'agenda_end_date',
      text: 'Selesai',
      className: 'postcode',
      sortable: true,
      cell: (record) => {
        const endDateTime = `${record.agenda_end_date}T${record.agenda_end_time}`
        return (
          <>
            <p>{moment(endDateTime).format('LLLL')}</p>
          </>
        )
      },
      width: '20%',
    },
    //desc
    {
      key: 'agenda_description',
      text: 'Keterangan',
      className: 'rating',
      sortable: true,
    },
    //status
    // {
    // key: 'agenda_status_name',
    // text: 'Status',
    // className: 'rating',
    // sortable: true,
    // },
    //location
    {
      key: 'agenda_location',
      text: 'Lokasi / URL',
      className: 'rating',
      sortable: true,
      cell: (record) => {
        const location = record.agenda_location
        return (
          <>
            {isValidHttpUrl(location) ? (
              <a href={location} target="_blank" rel="noreferrer">
                {location}
              </a>
            ) : (
              <span>{location}</span>
            )}
          </>
        )
      },
      //width: "10%",
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 120,
      sortable: false,
      align: "center",
      cell: (record) => {
        const thisId = record.agenda_uuid;
        return (
          <Fragment>
            <span>
              <button
                className="btn btn-primary btn-sm"
                id={"detail-" + thisId}
                onClick={() => {
                  this.setState({
                    detailUuid: record.agenda_uuid,
                  });
                }}
                style={{
                  marginRight: "2px",
                  padding: "2px 5px",
                }}
              >
                <i className="uil-align-left"></i>
              </button>
              <Tooltip
                style={{
                  marginBottom: 2,
                }}
                placement="top"
                isOpen={this.state["detail" + thisId]}
                target={"detail-" + thisId}
                toggle={() => {
                  this.setState({
                    ["detail" + thisId]: !this.state["detail" + thisId],
                  });
                }}
              >
                Detil
              </Tooltip>
            </span>
            {accessRole('update') && record.agenda_nip == this.userData.employee_nip ? (
              <span>
                <button
                  className="btn btn-success btn-sm"
                  id={"active-" + thisId}
                  onClick={() => {
                    this.setState({
                      activeUuid: record.agenda_uuid,
                      confirm: true,
                    });
                  }}
                  style={{
                    padding: "2px 5px",
                  }}
                >
                  <i className="uil-history-alt"></i>
                </button>
                <Tooltip
                  style={{
                    marginBottom: 2,
                  }}
                  placement="top"
                  isOpen={this.state["active" + thisId]}
                  target={"active-" + thisId}
                  toggle={() => {
                    this.setState({
                      ["active" + thisId]: !this.state["active" + thisId],
                    });
                  }}
                >
                  Aktifkan
                </Tooltip>
              </span>
            ) : null}
          </Fragment>
        );
      },
    },
  ];

  render() {
    return (
      <>
        <MKIDatatable
          className={this.configDatatable.tableClass}
          config={this.configDatatable}
          records={this.state.rows}
          columns={this.columns}
					minWidth={1000}
          dynamic={true}
          total_record={this.state.totalRow}
          initial={this.state.filter}
          onChange={(filter) => {
            this.setState(
              {
                loadingTable: true,
                filter: filter,
              },
              function () {
                this.setTokenAPI();
              }
            );
          }}
          loading={this.state.loadingTable}
        />
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan mengaktifkan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil diaktifkan"
          failedTitle="Gagal!"
          failedInfo="Data gagal diaktifkan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
        {this.state.detailUuid !== null ? (
          <Redirect to={"/admin/agenda.html?detail&uuid=" + this.state.detailUuid} />
        ) : null}
      </>
    );
  }
}

export default TableInactiveComponent;
