import React, { Fragment, Component } from 'react'
import {
  Container,
  Row, Col,
  CardBody, Card, CardTitle, CardSubtitle,
  Button,
  Input,
  Label,
  FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../helpers/ui/alert'
import { FileInput, generateFormData } from "../../../helpers/ui/FileInput";
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import CustomSelect from "../../../helpers/ui/CustomSelect"
import { Link, useHistory, Redirect } from "react-router-dom"

import apiResponse from '../../../services/apiResponse'

import CreateBloc from './Blocs/CreateBloc'


class IndexView extends Component {

  createBloc = new CreateBloc();

  constructor(props) {
    super(props)
    this.state = {
      defaultValueGuideType: "pdf",
      defaultValueVideoType: "youtube",
      isPdf: true,
      isYoutube: true,
      guide_type: [
        {
          label: "pdf",
          value: "pdf"
        },
        {
          label: "video",
          value: "video"
        }
      ],
      video_type: [
        {
          label: "youtube",
          value: "youtube"
        },
        {
          label: "upload",
          value: "upload"
        }
      ],
      optGuideCategory: [],
      dataForm: {}
    }
  }

  submitData = async () => {
    if (this.state.dataForm["youtube_link"]) {
      this.state.dataForm["youtube_link"] = this.generateYoutubeUrl(this.state.dataForm["youtube_link"]);
    }
    
    const formData = generateFormData(this.state.dataForm, {
      field: "attachment_detail",
      name: "guideAttachments/attachment",
    });
    await this.createBloc.fetchCreate(formData)
  }

  generateYoutubeUrl = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? "https://www.youtube.com/embed/" + match[2]
      : null;
  }

  handlerOnSubmit = (e, values) => {
    e.preventDefault()
    this.setState({
      dataForm: values,
      confirm: true
    })
  }

  confirmResponse = (response) => {
    let forState = {}
    switch (response) {
      case 'cancel':
        forState = {
          confirm: false
        }
        break;
      case 'confirm':
        forState = {
          loading: true,
          confirm: false
        }
        this.submitData()
        break;
      case 'success':
        forState = {
          success: false,
          redirect: true
        }
        break;
      case 'failed':
        forState = {
          failed: false
        }
        break;
      default:
    }
    this.setState(forState)
  }

  componentDidMount() {

    let reformatType = function (categoryList) {
      return categoryList.map(function (category) {
        let newObj = {};
        newObj["label"] = category.category_name;
        newObj["value"] = category.guide_category_id;
        return newObj;
      });
    }

    this.createBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break
        default:
          break
      }
    })

    this.createBloc.guideCategoryChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            optGuideCategory: reformatType(response.result)
          });
          break
        case apiResponse.ERROR:
          break
        default:
          break
      }
    })

    this.createBloc.fetchGuideCategories();
  }

  componentWillUnmount() {
    this.createBloc.createChannel.unsubscribe();
    this.createBloc.guideCategoryChannel.unsubscribe();
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Buat Panduan</CardTitle>
                  <CardSubtitle className="mb-3">
                    Buat panduan baru dengan mengisi form di bawah ini
                  </CardSubtitle>
                  <hr />
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                    onKeyPress={this.preventEnter}
                  >
                    <Row className="mb-3">
                      <label htmlFor="agenda_name" className="col-md-3 col-form-label">
                        Judul <span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <AvField
                          name="guide_name"
                          placeholder="Judul Panduan"
                          type="text"
                          errorMessage="Masukkan judul panduan"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="guide_name"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="agenda_location"
                        className="col-md-3 col-form-label"
                      >
                        Kategori Panduan <span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <CustomSelect
                          name="guide_category_id"
                          id="guide_category_id"
                          errorMessage="Silahkan pilih kategori panduan"
                          options={this.state.optGuideCategory}
                          validate={{ required: { value: true } }}
                          placeholder="Pilih kategori panduan" />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="agenda_location"
                        className="col-md-3 col-form-label"
                      >
                        Tipe Panduan <span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <CustomSelect
                          name="guide_type"
                          id="guide_type"
                          errorMessage="Silahkan pilih tipe panduan"
                          options={this.state.guide_type}
                          onChange={(o) => this.setState({ isPdf: o.value == "pdf" })}
                          defaultValue={this.state.defaultValueGuideType}
                          validate={{ required: { value: true } }}
                          placeholder="Pilih tipe panduan" />
                      </Col>
                    </Row>
                    {!this.state.isPdf ?
                      (
                        <Row className="mb-3">
                          <label
                            htmlFor="agenda_location"
                            className="col-md-3 col-form-label"
                          >
                            Tipe Video <span className="text-danger">*</span>
                          </label>
                          <Col md="9">
                            <CustomSelect
                              name="video_type"
                              id="video_type"
                              errorMessage="Silahkan pilih tipe video"
                              options={this.state.video_type}
                              validate={{ required: { value: !this.state.isPdf } }}
                              onChange={(o) => this.setState({ isYoutube: o.value == "youtube" })}
                              defaultValue={this.state.defaultValueVideoType}
                              placeholder="Pilih tipe video" />
                          </Col>
                        </Row>
                      ) : null
                    }

                    {!this.state.isPdf && this.state.isYoutube ?
                      (
                        <Row className="mb-3">
                          <label
                            htmlFor="agenda_location"
                            className="col-md-3 col-form-label"
                          >
                            Link Youtube <span className="text-danger">*</span>
                          </label>
                          <Col md="9">
                            <AvField
                              name="youtube_link"
                              placeholder="Link Youtube"
                              type="text"
                              errorMessage="Masukkan link youtube"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="youtube_link"
                            />
                          </Col>
                        </Row>
                      ) : null
                    }

                    {this.state.isPdf ? (
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">Pdf <span className="text-danger">*</span></label>
                        <Col md={9}>
                          <FileInput
                            name="attachment_detail"
                            validate={{ required: { value: true } }}
                            accept=".pdf"
                          />
                        </Col>
                      </Row>
                    ) : null}

                    {!this.state.isPdf && !this.state.isYoutube ? (
                      <Row className="mb-3">
                        <label className="col-md-3 col-form-label">Video <span className="text-danger">*</span></label>
                        <Col md={9}>
                          <FileInput
                            name="attachment_detail"
                            validate={{ required: { value: true } }}
                            accept=".mp4"
                          />
                        </Col>
                      </Row>
                    ) : null}

                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <div className="form-check form-switch mb-3">
                          <AvCheckboxGroup
                            name="checkboxExample"
                            required
                            errorMessage="Agreement harus di centang"
                          >
                            <AvCheckbox
                              label="Saya yakin dan bertanggung jawab atas data ini"
                              value="1"
                            />
                          </AvCheckboxGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <Link to="/admin/panduan.html">
                          <Button color="success">
                            <i className="uil-arrow-left"></i> Kembali
                          </Button>
                        </Link>{" "}
                        <Button color="danger" type="submit">
                          <i className="uil-save"></i> Simpan
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"

          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"

          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"

          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"

          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}

          response={this.confirmResponse}
        />
        {this.state.redirect ?
          <Redirect to='/admin/panduan.html' />
          : null}
      </Fragment>
    )
  }

}

export default IndexView