import moment from "moment";
import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { Row, Col, Tooltip } from "reactstrap";
import { accessRole } from "../../../../helpers/access_role";
import { config } from "../../../../helpers/config_global";
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import { isValidHttpUrl } from "../../../../helpers/utility";
import MKIDatatable from "../../../../modules/mki-datatable/lib";
import { cancelToken } from "../../../../services/adapters/base";
import apiResponse from "../../../../services/apiResponse";
import DataTableBloc from "../Blocs/DataTableBloc";

class TableActiveComponent extends Component {
	dataTableBloc = new DataTableBloc();

	source;
	historyTable = "historyTicketTableActive";
	defaultOrder = "ticket_created_at_date";
	defaultSize = 10;
	defaultSort = "desc";
	configDatatable = {
		...config("datatable"),
		sort: {
			column: this.defaultOrder,
			order: this.defaultSort,
		},
	};

	constructor(props) {
		super(props);
		this.state = {
			tooltip: false,
			detailUuid: null,
			updateUuid: null,
			notulenUuid: null,
			defaultSize: 10,
			rows: [],
			totalRow: 0,
			loadingTable: true,
			filter: sessionStorage.getItem(this.historyTable)
				? JSON.parse(sessionStorage.getItem(this.historyTable))
				: {
						filter_value: "",
						page_number: 1,
						page_size: this.defaultSize,
						sort_order: {
							column: this.defaultOrder,
							order: this.defaultSort,
						},
				  },
		};
	}
	toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

	loadData = async () => {
		/* history Datatable */
		sessionStorage.setItem(
			this.historyTable,
			JSON.stringify(this.state.filter)
		);

		/* kirim ke server */
		let offset =
			(this.state.filter.page_number - 1) * this.state.filter.page_size;
		if (this.state.defaultSize !== this.state.filter.page_size) {
			this.setState({
				defaultSize: this.state.filter.page_size,
			});
			offset = 0;
			this.configDatatable.page_number = 1;
		}
		const query = {
			search: this.state.filter.filter_value,
			limit: this.state.filter.page_size,
			offset: offset,
			order: this.state.filter.sort_order.column,
			sort: this.state.filter.sort_order.order,
			status: 1,
		};
		await this.dataTableBloc.fetchRowTable(query, this.source.token);
	};

	setTokenAPI = () => {
		if (typeof this.source != typeof undefined) {
			this.source.cancel();
		}
		this.source = cancelToken();
		this.loadData();
	};

	confirmResponse = (response) => {
		let forState = {};
		switch (response) {
			case "cancel":
				forState = {
					confirm: false,
				};
				break;
			case "confirm":
				forState = {
					loading: true,
					confirm: false,
				};
				break;
			case "success":
				forState = {
					success: false,
					redirect: true,
				};
				break;
			case "failed":
				forState = {
					failed: false,
				};
				break;
			default:
		}
		this.setState(forState);
	};

	componentDidMount() {
		this.setTokenAPI();

		this.dataTableBloc.rowtableChannel.subscribe((result) => {
			switch (result.status) {
				case apiResponse.INITIAL:
					this.setState({
						loadingTable: false,
					});
					break;
				case apiResponse.LOADING:
					this.setState({
						loadingTable: true,
					});
					break;
				case apiResponse.COMPLETED:
					const response = result.data.response;
					this.setState({
						totalRow: response.total,
						rows: response.result,
						loadingTable: false,
					});
					break;
				case apiResponse.ERROR:
					break;
				default:
					break;
			}
		});
	}

	componentWillUnmount() {
		this.dataTableBloc.rowtableChannel.unsubscribe();
	}

	columns = [
		{
			key: "ticket_tracking_id",
			text: "Tracking ID",
			className: "address",
			sortable: true,
			cell: (ticket) => {
				return (
					<Link to={`/admin/helpdesk.html?ticket=${ticket.ticket_uuid}`}>
						<span>{ticket.ticket_tracking_id.toUpperCase()}</span>
					</Link>
				);
			},
		},
		{
			key: "ticket_updated_date",
			text: "Updated",
			className: "address",
			sortable: true,
		},
		{
			key: "ticket_init_name",
			text: "Nama",
			className: "address",
			sortable: true,
		},
		//desc
		{
			key: "ticket_subject",
			text: "Subjek",
			className: "subjek",
			sortable: true,
		},
		//status
		{
			key: "ticket_status",
			text: "Status",
			className: "status",
			sortable: true,
			cell: (ticket) => {
				let className = "";
				let text = "";

				switch (ticket.ticket_status) {
					case 0:
						className = "text-danger";
						text = "New";
						break;

					case 1:
						className = "text-warning";
						text = "Unassigned";
						break;

					case 2:
						className = "text-warning";
						text = "Menunggu balasan";
						break;

					case 3:
						className = "text-primary";
						text = "Dibalas";
						break;

					case 4:
						className = "text-success";
						text = "Resolved";
						break;

					case 5:
						className = "text-danger";
						text = "Unresolved";
						break;

					default:
						text = "-";
						break;
				}

				return <span className={className}>{text}</span>;
			},
		},
		//location
		{
			key: "ticket_last_replier",
			text: "Terakhir dibalas",
			className: "last_reply",
			sortable: true,
			width: "10%",
		},
		//priority
		{
			key: "ticket_priority",
			text: "Prioritas",
			className: "priority",
			sortable: true,
			width: "10%",
			cell: (ticket) => {
				let className = "";
				let text = "";

				switch (ticket.ticket_status) {
					case 0:
						className = "text-secondary";
						text = "No Prior";
						break;

					case 1:
						className = "text-secondary";
						text = "Low";
						break;

					case 2:
						className = "text-success";
						text = "Medium";
						break;

					case 3:
						className = "text-warning";
						text = "High";
						break;

					case 4:
						className = "text-danger";
						text = "Critical";
						break;

					default:
						break;
				}

				return (
					<Row>
						<Col md={3}>
							<i className={`uil uil-circle ${className}`}></i>
						</Col>
						<Col md={9}>
							<span className={className}>{text}</span>
						</Col>
					</Row>
				);
			},
		},
		//action
		{
			key: "action",
			text: "Action",
			className: "action",
			width: 150,
			sortable: false,
			align: "center",
			cell: (record) => {
				const thisId = record.ticket_id;
				return (
					<Fragment>
						<span>
							<button
								className="btn btn-primary btn-sm"
								id={"detail-" + thisId}
								onClick={() => {
									this.setState({
										detailUuid: record.ticket_uuid,
									});
								}}
								style={{
									marginRight: "2px",
									marginBottom: "2px",
									padding: "2px 5px",
								}}
							>
								<i className="uil-align-left"></i>
							</button>
							<Tooltip
								style={{
									marginBottom: 2,
								}}
								placement="top"
								isOpen={this.state["detail" + thisId]}
								target={"detail-" + thisId}
								toggle={() => {
									this.setState({
										["detail" + thisId]: !this.state["detail" + thisId],
									});
								}}
							>
								Detil
							</Tooltip>
						</span>
						{accessRole("update") ? (
							<span>
								<button
									className="btn btn-warning btn-sm"
									id={"update-" + thisId}
									onClick={() => {
										this.setState({
											updateUuid: record.ticket_uuid,
										});
									}}
									style={{
										marginRight: "2px",
										marginBottom: "2px",
										padding: "2px 5px",
									}}
								>
									<i className="uil-pen"></i>
								</button>
								<Tooltip
									style={{
										marginBottom: 2,
									}}
									placement="top"
									isOpen={this.state["update" + thisId]}
									target={"update-" + thisId}
									toggle={() => {
										this.setState({
											["update" + thisId]: !this.state["update" + thisId],
										});
									}}
								>
									Perbaharui
								</Tooltip>
							</span>
						) : null}
						{accessRole("delete") ? (
							<span>
								<button
									className="btn btn-danger btn-sm"
									id={"delete-" + thisId}
									onClick={() => {
										this.setState({
											inactiveUuid: record.ticket_uuid,
											confirm: true,
										});
									}}
									style={{
										padding: "2px 5px",
										marginRight: "2px",
										marginBottom: "2px",
									}}
								>
									<i className="uil-trash-alt"></i>
								</button>
								<Tooltip
									style={{
										marginBottom: 2,
									}}
									placement="top"
									isOpen={this.state["delete" + thisId]}
									target={"delete-" + thisId}
									toggle={() => {
										this.setState({
											["delete" + thisId]: !this.state["delete" + thisId],
										});
									}}
								>
									Nonaktifkan
								</Tooltip>
							</span>
						) : null}
					</Fragment>
				);
			},
		},
	];

	render() {
		return (
			<>
				<MKIDatatable
					className={this.configDatatable.tableClass}
					config={this.configDatatable}
					records={this.state.rows}
					columns={this.columns}
					minWidth={1000}
					dynamic={true}
					total_record={this.state.totalRow}
					initial={this.state.filter}
					onChange={(filter) => {
						this.setState(
							{
								loadingTable: true,
								filter: filter,
							},
							function () {
								this.setTokenAPI();
							}
						);
					}}
					loading={this.state.loadingTable}
				/>
				<ConfirmAlert
					confirmTitle="Konfirmasi!"
					confirmInfo="Apakah anda yakin akan menghapus data ini?"
					loadingTitle="Mengirim data..."
					loadingInfo="Tunggu beberapa saat"
					successTitle="Berhasil!"
					successInfo="Data berhasil dihapus"
					failedTitle="Gagal!"
					failedInfo="Data gagal dihapus"
					showConfirm={this.state.confirm}
					showLoading={this.state.loading}
					showSuccess={this.state.success}
					showFailed={this.state.failed}
					response={this.confirmResponse}
				/>
				{this.state.detailUuid !== null ? (
					<Redirect to={"ticket.html?detail&uuid=" + this.state.detailUuid} />
				) : null}
				{this.state.updateUuid !== null ? (
					<Redirect to={"ticket.html?update&uuid=" + this.state.updateUuid} />
				) : null}
				{this.state.notulenUuid !== null ? (
					<Redirect to={"ticket.html?notulen&uuid=" + this.state.notulenUuid} />
				) : null}
			</>
		);
	}
}

export default TableActiveComponent;
