import { apiWrapper, apiCancel } from "../wrapper";

export const initial = "base";
const baseUrl = process.env.REACT_APP_SERVICE_BASE_URL;
const appCode = process.env.REACT_APP_SERVICE_BASE_APPCODE;
const outputType = "json";

export const authConnect = "auth/connect/";
export const notificationCheck = "notification/check/";
export const authGetAccessRole = "auth/getAccessRole/";
export const authGetAccessMenus = "auth/getAccessMenus/";

export const userGetData = 'user/getData/'
export const userList = 'user/getUsers/'
export const deviceList = 'user/getDevices/'
export const resetDevice = 'user/resetDevice/'

export const accessCreate = "access/create/";
export const menuList = "menu/list/";

//dashboard->notification
export const notificationList = "pages/notification/list/";
export const notificationFlagRead = "pages/notification/flag/";

//agenda
export const agendaList = "agenda/getAgendas/";
export const agendaDetail = "agenda/getAgenda/";
export const agendaCreate = "agenda/createAgenda/";
export const agendaUpdate = "agenda/updateAgenda/";
export const agendaActive = "agenda/activeAgenda/";
export const agendaInactive = "agenda/inactiveAgenda/";
export const agendaNotulensi = "agenda/notulensiAgenda/";

//ticket
export const ticketList = "ticket/getTickets/";
export const ticketDetail = "ticket/getTicket/";
export const ticketCreate = "ticket/createTicket/";
export const ticketUpdate = "ticket/updateTicket/";
export const ticketFetchPriority = "ticket/fetchPriority/";
export const ticketUpdateClassification = "ticket/updateClassification/";
export const ticketClose = "ticket/closeTicket/";
export const ticketReopen = "ticket/reopenTicket/";
export const ticketReply = "ticket/replyTicket/";
export const ticketReplies = "ticket/getTicketReplies/";
export const ticketEscalation = "ticket/escalationTicket/";
export const ticketBackToPic = "ticket/backToPic/";
export const ticketExport = "ticket/exportExcel/";

//announcement
export const announcementList = "announcement/getAnnouncements/";
export const announcementDetail = "announcement/getAnnouncement/";
export const announcementCreate = "announcement/createAnnouncement/";
export const announcementUpdate = "announcement/updateAnnouncement/";
export const announcementActive = "announcement/activeAnnouncement/";
export const announcementInactive = "announcement/inactiveAnnouncement/";

//guide
export const guideList = "guide/getGuides/";
export const guideDetail = "guide/getGuide/";
export const guideCreate = "guide/createGuide/";
export const guideUpdate = "guide/updateGuide/";
export const guideDelete = "guide/deleteGuide/";

//guide category
export const guideCategoryList = "guide/getGuideCategories/";
export const guideCategoryDetail = "guide/getGuideCategory/";
export const guideCategoryCreate = "guide/createGuideCategory/";
export const guideCategoryUpdate = "guide/updateGuideCategory/";
export const guideCategoryDelete = "guide/deleteGuideCategory/";

//portal service
export const serviceList = "portalService/getPortalServices/";
export const serviceDetail = "portalService/getPortalService/";
export const serviceCreate = "portalService/createPortalService/";
export const serviceUpdate = "portalService/updatePortalService/";
export const serviceDelete = "portalService/deletePortalService/";

//portal service feature
export const serviceFeatureList = "portalService/getPortalServiceFeatures/";
export const serviceFeatureDetail = "portalService/getPortalServiceFeature/";
export const serviceFeatureCreate = "portalService/createPortalServiceFeature/";
export const serviceFeatureUpdate = "portalService/updatePortalServiceFeature/";
export const serviceFeatureDelete = "portalService/deletePortalServiceFeature/";

//helpdesk PIC
export const helpdeskPicList = "helpdeskPic/getHelpdeskPicList/";
export const helpdeskPicDetail = "helpdeskPic/getHelpdeskPic/";
export const helpdeskPicCreate = "helpdeskPic/createHelpdeskPic/";
export const helpdeskPicUpdate = "helpdeskPic/updateHelpdeskPic/";
export const helpdeskPicDelete = "helpdeskPic/deleteHelpdeskPic/";

//Helpdesk Service
export const helpdeskServiceList = "helpdesk/getHelpdeskServices/";
export const helpdeskServices = "helpdeskService/getHelpdeskServices/";
export const helpdeskServiceDetail = "helpdeskService/getHelpdeskService/";
export const helpdeskServiceCreate = "helpdeskService/createHelpdeskService/";
export const helpdeskServiceUpdate = "helpdeskService/updateHelpdeskService/";
export const helpdeskServiceDelete = "helpdeskService/deleteHelpdeskService/";

//Helpdesk Modules
export const helpdeskModuleList = "helpdeskModule/getHelpdeskModules/";
export const helpdeskModuleDetail = "helpdeskModule/getHelpdeskModule/";
export const helpdeskModuleCreate = "helpdeskModule/createHelpdeskModule/";
export const helpdeskModuleUpdate = "helpdeskModule/updateHelpdeskModule/";
export const helpdeskModuleDelete = "helpdeskModule/deleteHelpdeskModule/";

//Meeting Registration
export const meetingRegistrationList = "meetingRegistration/getMeetingRegistrations/";
export const meetingRegistrationDetail = "meetingRegistration/getMeetingRegistration/";
export const meetingRegistrationCreate = "meetingRegistration/createMeetingRegistration/";
export const meetingRegistrationUpdate = "meetingRegistration/updateMeetingRegistration/";
export const meetingRegistrationDelete = "meetingRegistration/deleteMeetingRegistration/";
export const meetingRegistrationCancel = "meetingRegistration/cancelMeetingRegistration/";

//NLP Keyword
export const keywordList = "keyword/getListKeyword/";
export const keywordDetail = "keyword/getKeyword/";
export const keywordCreate = "keyword/createKeyword/";
export const keywordUpdate = "keyword/updateKeyword/";
export const keywordDelete = "keyword/deleteKeyword/";
export const keywordDeleteAll = "keyword/deleteAllKeyword/";
export const getRedirectLinkList = "keyword/getRedirectLinkList/";

//NLP Roughs
export const roughList = "rough/getListRough/";
export const roughDetail = "rough/getRough/";
export const roughCreate = "rough/createRough/";
export const roughUpdate = "rough/updateRough/";
export const roughDelete = "rough/deleteRough/";

//employee
export const searchEmployee = "user/searchUser/";

//dashboard->activity
export const activityList = "pages/activity/list/";

//export const cancelToken = () => apiCancel
export const cancelToken = () => {
  return apiCancel();
};
export const apiRequest = async (
  method,
  route,
  data = { params: null, body: null, cancelToken: null },
  needToken = true
) => {
  if (needToken) {
    const thisTime = Math.floor(Date.now() / 1000);
    const expire = localStorage.getItem(initial + "accessExpired");
    if (expire < thisTime) {
      await apiWrapper(
        baseUrl,
        initial,
        appCode,
        outputType,
        "post",
        "auth/getAccessToken/",
        data
      )
        .then((result) => {
          localStorage.setItem(
            initial + "accessToken",
            result.response.access_token
          );
          return apiWrapper(
            baseUrl,
            initial,
            appCode,
            outputType,
            method,
            route,
            data
          );
        })
        .catch((result) => {
          return Promise.reject("Tidak dapat mengambil accessToken");
        });
    } else {
      return apiWrapper(
        baseUrl,
        initial,
        appCode,
        outputType,
        method,
        route,
        data
      );
    }
  }
  return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data);
};
