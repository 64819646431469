import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from "availity-reactstrap-validation";

import Select from "react-select";
import { FileInput, generateFormData } from "../../../helpers/ui/FileInput";
import { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import MinioClient from "../../../services/minioClient.jsx";

import { ConfirmAlert } from "../../../helpers/ui/alert";
import apiResponse from "../../../services/apiResponse";
import CreateBloc from "./Blocs/CreateBloc";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Styles/style.scss";

class IndexView extends Component {
  createBloc = new CreateBloc();
  user = JSON.parse(localStorage.getItem("userdata"));
  minio = new MinioClient();

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {},
    };
  }

  dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    input: (styles) => ({ ...styles, ...this.dot() }),
    placeholder: (styles) => ({ ...styles, ...this.dot("#ccc") }),
    singleValue: (styles, { data }) => {
      const color =
        data.value === 0
          ? "gray"
          : data.value === 1
          ? "gray"
          : data.value === 2
          ? "green"
          : data.value === 3
          ? "yellow"
          : data.value === 4
          ? "red"
          : "transparent";
      return { ...styles, ...this.dot(color) };
    },
  };

  handleInputChange = (e) => {
    let val, field;
    try {
      val = e.currentTarget.value;
      field = e.currentTarget.name;
    } catch (err) {
      if (e.value && e.label) {
        val = e.value;
        field = "ticket_priority";
      }
    }

    this.setState({
      dataForm: {
        ...this.state.dataForm,
        [field]: val,
      },
    });
  };

  submitData = async () => {
    const formData = generateFormData(this.state.dataForm, {
      field: "document",
      name: "ticketAttachments/attachment",
    });
    await this.createBloc.fetchCreate(formData);
  };

  handlerOnSubmit = (e, values) => {
    e.preventDefault();
    this.setState({
      ...this.state.dataForm,
      ...values,
    });
    this.setState({
      confirm: true,
    });
  };

  preventEnter = (e) => {
    if (e.which === 13 && e.target.type !== "textarea") {
      e.preventDefault();
    }
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    const initName = document.getElementById("ticket_init_name");
    const initEmail = document.getElementById("ticket_init_email");
    this.createBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    if (
      (initName.value !== null ||
        initName.value !== undefined ||
        initName.value !== "") &&
      (initEmail.value !== null ||
        initEmail.value !== undefined ||
        initEmail.value !== "")
    ) {
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          ticket_init_name: initName.value,
          ticket_init_email: initEmail.value,
        },
      });
    }
  }

  renderCardForm = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>Buat Tiket</CardTitle>
          <CardSubtitle className="mb-3">
            Buat tiket baru dengan mengisi form di bawah ini
          </CardSubtitle>
          <hr />
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
            onKeyPress={this.preventEnter}
          >
            <AvField
              name="ticket_init_name"
              placeholder="Nama"
              type="hidden"
              errorMessage="Masukkan nama"
              className="form-control"
              id="ticket_init_name"
              value={this.user.employee_name || null}
            />
            <AvField
              name="ticket_init_email"
              placeholder="Email"
              type="hidden"
              errorMessage="Masukkan email"
              className="form-control"
              validate={{ required: { value: true } }}
              id="ticket_init_email"
              onChange={this.handleInputChange}
              value={this.user.employee_email_internal || null}
            />

            <Row className="mb-3">
              <label
                htmlFor="ticket_subject"
                className="col-md-3 col-form-label"
              >
                Subjek <span className="text-danger">*</span>
              </label>
              <Col md="9">
                <AvField
                  name="ticket_subject"
                  placeholder="Subjek"
                  type="text"
                  errorMessage="Subjek tidak boleh kosong"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="ticket_subject"
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="ticket_priority"
                className="col-md-3 col-form-label"
              >
                Prioritas
              </label>
              <Col md="9">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={false}
                  name="ticket_priority"
                  options={[
                    { label: "Pilih prioritas...", value: 0 },
                    { label: "Low", value: 1 },
                    { label: "Medium", value: 2 },
                    { label: "High", value: 3 },
                    { label: "Critical", value: 4 },
                  ]}
                  styles={this.colourStyles}
                  id="ticket_priority"
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="ticket_content"
                className="col-md-3 col-form-label"
              >
                Deskripsi <span className="text-danger">*</span>
              </label>
              <Col md={9}>
                <AvField
                  name="ticket_content"
                  placeholder="Deskripsi keluhan..."
                  type="textarea"
                  errorMessage="Deskripsi tidak boleh kosong"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  rows={8}
                  id="ticket_content"
                  onChange={this.handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <label
                htmlFor="ticket_content"
                className="col-md-3 col-form-label"
              >
                Lampiran
              </label>
              <div className="col-md-9">
                <FileInput
                  name="ticket_attachment"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                />
              </div>
            </Row>
            <Row className="mt-3">
              <Col md="3"></Col>
              <Col md="9">
                <div className="form-check form-switch mb-3">
                  <AvCheckboxGroup
                    name="checkboxExample"
                    required
                    errorMessage="Agreement harus di centang"
                  >
                    <AvCheckbox
                      label="Saya yakin dan bertanggung jawab atas data ini"
                      value="1"
                    />
                  </AvCheckboxGroup>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="3"></Col>
              <Col md="9">
                <Link to="helpdesk.html">
                  <Button color="success">
                    <i className="uil-arrow-left"></i> Kembali
                  </Button>
                </Link>{" "}
                <Button color="danger" type="submit">
                  <i className="uil-save"></i> Simpan
                </Button>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    );
  };

  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Col className="col-12">{this.renderCardForm()}</Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
        {this.state.redirect ? <Redirect to="/admin/helpdesk.html" /> : null}
      </>
    );
  }
}

export default IndexView;
