import moment from "moment";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
  Modal,
  ModalBody
} from "reactstrap";
import { isValidHttpUrl } from "../../../helpers/utility";
import apiResponse from "../../../services/apiResponse";
import DetailBloc from "./Blocs/DetailBloc";
import MinioClient from "../../../services/minioClient";
import { Icon } from "@iconify/react";
import ReactToPrint from 'react-to-print';
import logoFull from '../../../assets/images/logo-sm.png'
import './style.scss';

class IndexView extends Component {
  detailBloc = new DetailBloc();
  minio = new MinioClient();

  constructor(props) {
    super(props);
    this.state = {
      showQrInternalModal: false,
      showQrExternalModal: false
    };
  }

  componentDidMount() {
    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response;
            this.setState({
              detail: response,
            });
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    const queryParams = new URLSearchParams(this.props.location.search);
    this.detailBloc.fetchDetail({ meeting_registration_uuid: queryParams.get("id") });
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Detil Registrasi Rapat</CardTitle>
                  <CardSubtitle className="mb-3">
                    Keterangan detil mengenai registrasi rapat
                  </CardSubtitle>
                  <hr />
                  <Row className="mb-3">
                    <label className="col-md-3">Nama Rapat</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.meeting_registration_name !== ""
                            ? this.state.detail.meeting_registration_name
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: 68, height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Tanggal Rapat</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.meeting_registration_date !== ""
                            ? moment(this.state.detail.meeting_registration_date).format('LL')
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Jam Rapat</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.meeting_registration_start_time !== ""
                            ? this.state.detail.meeting_registration_start_time + " - " + this.state.detail.meeting_registration_end_time
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3">Jenis Rapat</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.meeting_registration_type !== ""
                            ? this.state.detail.meeting_registration_type == 1 ? "Online" : "Offline"
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  {this.state.detail != null && this.state.detail.meeting_registration_type == 2 ? (

                  <Row className="mb-3">
                    <label className="col-md-3">Lokasi Rapat</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.meeting_registration_place !== ""
                            ? this.state.detail.meeting_registration_place
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  ) : null}

                  {this.state.detail != null && this.state.detail.meeting_registration_type == 1 ? (
                  <Row className="mb-3">
                    <label className="col-md-3">Link Rapat</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.meeting_registration_link !== ""
                            ? <a href={this.state.detail.meeting_registration_link}>{this.state.detail.meeting_registration_link}</a>
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  ) : null }

                  <Row className="mb-3">
                    <label className="col-md-3">Link Kehadiran (Internal)</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          <div>
                          {this.state.detail.meeting_registration_attendance_link_internal !== ""
                            ? <a href={this.state.detail.meeting_registration_attendance_link_internal}>{this.state.detail.meeting_registration_attendance_link_internal}</a>
                            : "-"}
                          </div>

                          <div style={{ position: "relative", display: "inline-block" }}>
                            <img
                              src={'https://qrcode.tec-it.com/API/QRCode?data=' + this.state.detail.meeting_registration_attendance_link_internal + '&dpi=96&size=small&errorcorrection=H'}
                              className="qr-code img-thumbnail img-responsive"
                              alt="Image"
                            />
                            <img
                              src={logoFull}
                              style={{
                                position: "absolute",
                                padding: "3px",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1,
                                width: "25%",
                                height: "25%",
                                background: "white"
                              }}
                              alt="Logo"
                            />
                          </div>
                        
                            <Button color="warning" style={{ marginRight: "5px" }} onClick={() => this.setState({showQrInternalModal: true})} target="_blank"> Lihat detail </Button>
                            <ReactToPrint
                              trigger={() => {
                                return <Button color="info"> Print </Button>;
                              }}
                              content={() => this.internalQrRef}
                            />
                          
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3">Link Kehadiran (Eksternal)</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          <div>
                          {this.state.detail.meeting_registration_attendance_link_external !== ""
                            ? <a href={this.state.detail.meeting_registration_attendance_link_external}>{this.state.detail.meeting_registration_attendance_link_external}</a>
                            : "-"}
                          </div>
                          
                          <div style={{ position: "relative", display: "inline-block" }}>
                            <img
                              src={'https://qrcode.tec-it.com/API/QRCode?data=' + this.state.detail.meeting_registration_attendance_link_external + '&dpi=96&size=small&errorcorrection=H'}
                              className="qr-code img-thumbnail img-responsive"
                              alt="Image"
                            />
                            <img
                              src={logoFull}
                              style={{
                                position: "absolute",
                                padding: "3px",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1,
                                width: "25%",
                                height: "25%",
                                background: "white"
                              }}
                              alt="Logo"
                            />
                          </div>

                          <Button color="warning" style={{ marginRight: "5px" }} onClick={() => this.setState({showQrExternalModal: true})} target="_blank"> Lihat detail </Button>
                          <ReactToPrint
                              trigger={() => {
                                return <Button color="info"> Print </Button>;
                              }}
                              content={() => this.externalQrRef}
                            />
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3">Partisipan (Internal)</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.participant_internals.length > 0 ? (
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">NIP</th>
                                  <th scope="col">Nama</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.detail.participant_internals.map(
                                  (participant, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <th scope="row">{idx + 1}</th>
                                        <td>{participant.employee_nip}</td>
                                        <td>{participant.employee_name}</td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          ) : (
                            "-"
                          )}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3">Partisipan (External)</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.participant_externals.length > 0 ? (
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Nama</th>
                                  <th scope="col">Email</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.detail.participant_externals.map(
                                  (participant, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <th scope="row">{idx + 1}</th>
                                        <td>{participant.meeting_registration_participant_external_name}</td>
                                        <td>{participant.meeting_registration_participant_external_email}</td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          ) : (
                            "-"
                          )}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3">Daftar Hadir</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.attendances.length > 0 ? (
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Nama</th>
                                  <th scope="col">NIP</th>
                                  <th scope="col">Instansi</th>
                                  <th scope="col">No Telp</th>
                                  <th scope="col">Email</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.detail.attendances.map(
                                  (attendance, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <th scope="row">{idx + 1}</th>
                                        <td>{attendance.meeting_registration_attendance_name}</td>
                                        <td>{attendance.meeting_registration_attendance_nip ?? "-"}</td>
                                        <td>{attendance.meeting_registration_attendance_company}</td>
                                        <td>{attendance.meeting_registration_attendance_no_telp}</td>
                                        <td>{attendance.meeting_registration_attendance_email}</td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          ) : (
                            "-"
                          )}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md="3"></Col>
                    <Col md="9">
                      <Link to="meeting_registration.html" className="waves-effect">
                        <Button color="danger">
                          <i className="uil-arrow-left"></i> Kembali
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {this.state.detail != null && (
          <div style={{ display: "none" }}>
            <center ref={el => (this.internalQrRef = el)}>
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  src={'https://qrcode.tec-it.com/API/QRCode?data=' + this.state.detail.meeting_registration_attendance_link_internal + '&dpi=150&size=medium&errorcorrection=H'}
                  className="qr-code img-thumbnail img-responsive"
                  alt="Image"
                />
                <img
                  src={logoFull}
                  style={{
                    position: "absolute",
                    padding: "3px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                    width: "25%",
                    height: "25%",
                    background: "white"
                  }}
                  alt="Logo"
                />
              </div>
            </center>
          </div>
        )}
        {this.state.detail != null && (
          <div style={{ display: "none" }}>
            <center ref={el => (this.externalQrRef = el)}>
              <div style={{ position: "relative", display: "inline-block" }}>
                <img
                  src={'https://qrcode.tec-it.com/API/QRCode?data=' + this.state.detail.meeting_registration_attendance_link_external + '&dpi=150&size=medium&errorcorrection=H'}
                  className="qr-code img-thumbnail img-responsive"
                  alt="Image"
                />
                <img
                  src={logoFull}
                  style={{
                    position: "absolute",
                    padding: "3px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                    width: "25%",
                    height: "25%",
                    background: "white"
                  }}
                  alt="Logo"
                />
              </div>
            </center>
          </div>
        )}
        {this.state.detail && (
          <Modal
          isOpen={this.state.showQrInternalModal}
          centered={true}
          size="xl"
        >
          <ModalBody style={{ padding: "0.15rem" }}>
            <div className="close-btn" onClick={() => {
                this.setState({
                  showQrInternalModal: false
                })
              }}>
              <Icon icon="icomoon-free:cross" width={20} />
            </div>

            <div id='printablediv' style={{ padding: 30 }}>
              <center>
                <Row className="mb-4">
                  <center>
                    <h1>Scan Disini</h1>
                    <h3>Untuk mengisi kehadiran</h3>
                  </center>
                </Row>
                <div className="qr-bordered" style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={'https://qrcode.tec-it.com/API/QRCode?data=' + this.state.detail.meeting_registration_attendance_link_internal + '&dpi=150&size=medium&errorcorrection=H'}
                    className="qr-code img-thumbnail img-responsive"
                    alt="Image"
                  />
                  <img
                    src={logoFull}
                    style={{
                      position: "absolute",
                      padding: "3px",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 1,
                      width: "25%",
                      height: "25%",
                      background: "white"
                    }}
                    alt="Logo"
                  />
                </div>
              </center>
            </div>
          </ModalBody>
        </Modal>       
        )}

        {this.state.detail && (
          <Modal
            isOpen={this.state.showQrExternalModal}
            centered={true}
            size="xl">
            <ModalBody style={{ padding: "0.15rem" }}>
              <div className="close-btn" onClick={() => {
                  this.setState({
                    showQrExternalModal: false
                  })
                }}>
                <Icon icon="icomoon-free:cross" width={20} />
              </div>

              <div  id='printablediv' style={{ padding: 30 }}>
                <center>
                  <Row className="mb-4">
                    <center>
                      <h1>Scan Disini</h1>
                      <h3>Untuk mengisi kehadiran</h3>
                    </center>
                  </Row>
                  <div className="qr-bordered" style={{ position: "relative", display: "inline-block" }}>
                    <img
                      src={'https://qrcode.tec-it.com/API/QRCode?data=' + this.state.detail.meeting_registration_attendance_link_external + '&dpi=150&size=medium&errorcorrection=H'}
                      className="qr-code img-thumbnail img-responsive"
                      alt="Image"
                    />
                    <img
                      src={logoFull}
                      style={{
                        position: "absolute",
                        padding: "3px",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1,
                        width: "25%",
                        height: "25%",
                        background: "white"
                      }}
                      alt="Logo"
                    />
                  </div>
                </center>
              </div>
            </ModalBody>
          </Modal>
        )}
        
      </Fragment>
    );
  }
}

//export default IndexView
export default withRouter(IndexView);
