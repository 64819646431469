import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { Component, Fragment } from 'react';
import { Link, Redirect } from "react-router-dom";
import {
  Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container,
  Row
} from "reactstrap";
import { ConfirmAlert } from '../../../helpers/ui/alert';
import CustomSelect from "../../../helpers/ui/CustomSelect";
import { generateFormData } from "../../../helpers/ui/FileInput";
import apiResponse from '../../../services/apiResponse';
import CreateBloc from './Blocs/CreateBloc';


class IndexView extends Component {

  createBloc = new CreateBloc();

  constructor(props) {
    super(props)
    this.state = {
      optPortalServices: [],
      dataForm: {}
    }
  }

  submitData = async () => {
    const formData = generateFormData(this.state.dataForm, {
      field: "attachment_detail",
      name: "portalServiceFeatureAttachments/attachment",
    });
    await this.createBloc.fetchCreate(formData)
  }

  handlerOnSubmit = (e, values) => {
    e.preventDefault()
    this.setState({
      dataForm: values,
      confirm: true
    })
  }

  confirmResponse = (response) => {
    let forState = {}
    switch (response) {
      case 'cancel':
        forState = {
          confirm: false
        }
        break;
      case 'confirm':
        forState = {
          loading: true,
          confirm: false
        }
        this.submitData()
        break;
      case 'success':
        forState = {
          success: false,
          redirect: true
        }
        break;
      case 'failed':
        forState = {
          failed: false
        }
        break;
      default:
    }
    this.setState(forState)
  }

  componentDidMount() {

    let reformatType = function (portalServices) {
      return portalServices.map(function (service) {
        let newObj = {};
        newObj["label"] = service.service_title;
        newObj["value"] = service.portal_service_id;
        return newObj;
      });
    }

    this.createBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break
        default:
          break
      }
    })

    this.createBloc.portalServiceChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            optPortalServices: reformatType(response.result)
          });
          break
        case apiResponse.ERROR:
          break
        default:
          break
      }
    })

    this.createBloc.fetchPortalServices();
  }

  componentWillUnmount() {
    this.createBloc.createChannel.unsubscribe();
    this.createBloc.portalServiceChannel.unsubscribe();
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Buat Fitur Layanan</CardTitle>
                  <CardSubtitle className="mb-3">
                    Buat fitur layanan baru dengan mengisi form di bawah ini
                  </CardSubtitle>
                  <hr />
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                    onKeyPress={this.preventEnter}
                  >
                    <Row className="mb-3">
                      <label htmlFor="service_feature_title" className="col-md-3 col-form-label">
                        Judul Layanan<span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <AvField
                          name="service_feature_title"
                          placeholder="Judul Layanan"
                          type="text"
                          errorMessage="Masukkan judul layanan"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="service_feature_title"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="service_feature_link"
                        className="col-md-3 col-form-label"
                      >
                        Kategori Layanan <span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <CustomSelect
                          name="portal_service_id"
                          id="portal_service_id"
                          errorMessage="Silahkan pilih kategori layanan"
                          options={this.state.optPortalServices}
                          validate={{ required: { value: true } }}
                          placeholder="Pilih kategori layanan" />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="service_feature_icon" className="col-md-3 col-form-label">
                        Nama Icon<span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <AvField
                          name="service_feature_icon"
                          placeholder="Nama Icon"
                          type="text"
                          errorMessage="Masukkan nama icon"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="service_feature_icon"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="service_feature_link" className="col-md-3 col-form-label">
                        Link Layanan<span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <AvField
                          name="service_feature_link"
                          placeholder="Link Layanan"
                          type="text"
                          errorMessage="Masukkan link layanan"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="service_feature_link"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <Link to="/admin/fitur_layanan_portal.html">
                          <Button color="success">
                            <i className="uil-arrow-left"></i> Kembali
                          </Button>
                        </Link>{" "}
                        <Button color="danger" type="submit">
                          <i className="uil-save"></i> Simpan
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"

          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"

          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"

          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"

          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}

          response={this.confirmResponse}
        />
        {this.state.redirect ?
          <Redirect to='/admin/fitur_layanan_portal.html' />
          : null}
      </Fragment>
    )
  }

}

export default IndexView