import React, { Fragment, Component } from 'react'
import {
  Container,
  Row, Col,
  CardBody, Card, CardTitle, CardSubtitle,
  Button,
  Input,
  Label,
  FormGroup, CustomInput
} from "reactstrap"
import { ConfirmAlert } from '../../../helpers/ui/alert'
import { AvForm, AvGroup, AvInput, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { FileInput, generateFormData } from "../../../helpers/ui/FileInput";
import { Link, useHistory, Redirect } from "react-router-dom"

import apiResponse from '../../../services/apiResponse'

import CreateBloc from './Blocs/CreateBloc'

import CustomSelect from "../../../helpers/ui/CustomSelect"


class IndexView extends Component {

  createBloc = new CreateBloc();

  constructor(props) {
    super(props)
    this.state = {
      dataForm: {},
      optServiceStatus: [
        {
          label: "Aktif",
          value: "1"
        },
        {
          label: "Tidak Aktif",
          value: "0"
        }
      ],
    }
  }

  submitData = async () => {
    const formData = generateFormData(this.state.dataForm, {
      field: "attachment_detail",
      name: "portalServiceAttachments/attachment",
    });

    await this.createBloc.fetchCreate(formData)
  }

  handlerOnSubmit = (e, values) => {
    e.preventDefault()
    this.setState({
      dataForm: values,
      confirm: true
    })
  }

  confirmResponse = (response) => {
    let forState = {}
    switch (response) {
      case 'cancel':
        forState = {
          confirm: false
        }
        break;
      case 'confirm':
        forState = {
          loading: true,
          confirm: false
        }
        this.submitData()
        break;
      case 'success':
        forState = {
          success: false,
          redirect: true
        }
        break;
      case 'failed':
        forState = {
          failed: false
        }
        break;
      default:
    }
    this.setState(forState)
  }

  componentDidMount() {
    this.createBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break
        default:
          break
      }
    })
  }

  componentWillUnmount() {
    this.createBloc.createChannel.unsubscribe();
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Buat Layanan Portal</CardTitle>
                  <CardSubtitle className="mb-3">
                    Buat layanan portal baru dengan mengisi form di bawah ini
                  </CardSubtitle>
                  <hr />
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                  >
                    <Row className="mb-3">
                      <label
                        htmlFor="fielCode"
                        className="col-md-3 col-form-label"
                      >
                        Judul Layanan <span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <AvField
                          name="service_title"
                          placeholder="Judul Layanan"
                          type="text"
                          errorMessage="Masukan judul layanan"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="service_title"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="is_popup_announcement"
                        className="col-md-3 col-form-label"
                      >
                        Status <span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <CustomSelect
                          name="is_active"
                          id="is_active"
                          options={this.state.optServiceStatus}
                          validate={{ required: { value: true } }}
                          defaultValue="1"
                          placeholder="Pilih Status" />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="fielCode"
                        className="col-md-3 col-form-label"
                      >
                        Warna Background  <span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <AvField
                          name="service_color"
                          placeholder="#EFFEFC"
                          type="text"
                          errorMessage="Masukan warna background"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="service_color"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="fielCode"
                        className="col-md-3 col-form-label"
                      >
                        Warna Background Fitur  <span className="text-danger">*</span>
                      </label>
                      <Col md="9">
                        <AvField
                          name="service_features_color"
                          placeholder="#EFFEFC"
                          type="text"
                          errorMessage="Masukan warna background fitur"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="service_features_color"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="fielCode"
                        className="col-md-3 col-form-label"
                      >
                        Link Layanan
                      </label>
                      <Col md="9">
                        <AvField
                          name="service_link"
                          placeholder="Link Layanan"
                          type="text"
                          errorMessage="Masukan link layanan"
                          className="form-control"
                          id="service_link"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-3 col-form-label">Icon <span className="text-danger">*</span></label>
                      <Col md={9}>
                        <FileInput
                          name="attachment_detail"
                          validate={{ required: { value: true } }}
                          accept=".jpg,.png,.svg"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <div className="form-check form-switch mb-3">
                          <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                            <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                          </AvCheckboxGroup>

                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <Link to="/admin/layanan_portal.html">
                          <Button color="success"><i className="uil-arrow-left"></i> Kembali</Button>
                        </Link>
                        {' '}
                        <Button color="danger" type="submit">
                          <i className="uil-arrow-up-right"></i> Simpan
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"

          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"

          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"

          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"

          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}

          response={this.confirmResponse}
        />
        {this.state.redirect ?
          <Redirect to='/admin/layanan_portal.html' />
          : null}
      </Fragment>
    )
  }

}

export default IndexView