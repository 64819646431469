import { AvField, AvForm } from "availity-reactstrap-validation";

import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import apiResponse from "../../../services/apiResponse";
import DetailBloc from "./Blocs/DetailBloc";
import ReplyBloc from "./Blocs/ReplyBloc";
import DataTableBloc from "../Index/Blocs/DataTableBloc";
import PriorityBloc from "./Blocs/PriorityBloc";
import { Icon } from "@iconify/react";
import { ConfirmAlert } from "../../../helpers/ui/alert";
import ReactTimeAgo from "react-time-ago";
import { cancelToken } from "../../../services/adapters/base";

class IndexView extends Component {
  userdata = JSON.parse(localStorage.getItem("userdata"));
  detailBloc = new DetailBloc();
  replyBloc = new ReplyBloc();
  dataTableBloc = new DataTableBloc();
  priorityBloc = new PriorityBloc();
  source;

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {
        ticket_reply_sender_uuid: this.userdata.employee_uuid,
      },
      detail: {},
    };
  }

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
  };

  dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    input: (styles) => ({ ...styles, ...this.dot() }),
    placeholder: (styles) => ({ ...styles, ...this.dot("#ccc") }),
    singleValue: (styles, { data }) => {
      const color =
        data.value === 0
          ? "gray"
          : data.value === 1
          ? "gray"
          : data.value === 2
          ? "green"
          : data.value === 3
          ? "yellow"
          : data.value === 4
          ? "red"
          : "transparent";
      return { ...styles, ...this.dot(color) };
    },
  };

  handleInputChange = (e) => {
    let val, field;
    if (e.currentTarget) {
      val = e.currentTarget.value;
      field = e.currentTarget.name;
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          [field]: val,
        },
      });
    } else if (e.value && e.label) {
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          ticket_priority: e.value,
        },
      });
      this.submitPriorityChange(e.value);
    }
  };

  submitPriorityChange = (prior) => {
    this.priorityBloc.fetchPriority({
      ...this.state.dataForm,
      ticket_priority: prior,
    });
  };

  handlerOnSubmit = (e, values) => {
    e.preventDefault();

    const queryParams = new URLSearchParams(this.props.location.search);

    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ticket_reply_ticket_uuid: queryParams.get("uuid"),
      },
    });

    this.setState({
      ...this.dataForm,
      ...values,
    });

    this.setState({
      confirm: true,
    });
  };

  submitData = async () => {
    await this.replyBloc.fetchReply(this.state.dataForm);
  };

  handlerOnCloseTicket = () => {
    this.setState({
      confirmClose: true,
    });
  };

  closeTicket = async () => {
    const queryParams = new URLSearchParams(this.props.location.search);

    await this.dataTableBloc.fetchCloseTicket({
      ticket_uuid: queryParams.get("uuid"),
    });
  };

  componentDidMount() {
    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            this.setState({
              detail: {
                ...response,
                ticket_priority_color:
                  response.ticket_priority === 0 ||
                  response.ticket_priority === 1
                    ? "secondary"
                    : response.ticket_priority === 2
                    ? "success"
                    : response.ticket_priority === 3
                    ? "warning"
                    : "danger",
                ticket_priority_text:
                  response.ticket_priority === 0
                    ? "No Prior"
                    : response.ticket_priority === 1
                    ? "Low"
                    : response.ticket_priority === 2
                    ? "Medium"
                    : response.ticket_priority === 3
                    ? "High"
                    : "Critical",
              },

              dataForm: {
                ...this.state.dataForm,
                ticket_uuid: response.ticket_uuid,
                ticket_priority: response.ticket_priority,
              },
            });
            console.log("DF:", this.state.dataForm);
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    const queryParams = new URLSearchParams(this.props.location.search);
    this.detailBloc.fetchDetail({ uuid: queryParams.get("uuid") });

    this.replyBloc.repliesChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            this.setState({
              detail: {
                ...this.state.detail,
                ticket_replies: response,
              },
            });
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    this.replyBloc.fetchReplies({ ticket_uuid: queryParams.get("uuid") });

    this.replyBloc.replyChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.dataTableBloc.closeTicketChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState(
            {
              loading: false,
              success: true,
              loadingTable: true,
              redirect: true,
            },
            function () {
              this.setTokenAPI();
            }
          );
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.priorityBloc.priorityChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.detailBloc.detailChannel.unsubscribe();
    this.replyBloc.repliesChannel.unsubscribe();
    this.replyBloc.replyChannel.unsubscribe();
    this.dataTableBloc.closeTicketChannel.unsubscribe();
    this.priorityBloc.priorityChannel.unsubscribe();
  }

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  confirmCloseTicket = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirmClose: false,
        };
        break;
      case "confirm":
        forState = {
          loadingClose: true,
          confirmClose: false,
        };
        this.closeTicket();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failedClose: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Detail Ticket</CardTitle>
                  <CardSubtitle className="mb-3">
                    Keterangan detil mengenai ticket
                  </CardSubtitle>
                  <hr />
                  {/*<Row className="mb-3">
                    <label className="col-md-3">Ticket Tracking ID</label>
                    <Col md="9" style={{ textTransform: "uppercase" }}>
                      {this.state.detail ? (
                        <strong>
                          {this.state.detail.ticket_tracking_id !== ""
                            ? this.state.detail.ticket_tracking_id
                            : "-"}
                        </strong>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>*/}
                  <Row className="mb-3">
                    <label className="col-md-3">Nama Pengadu</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.ticket_init_name !== ""
                            ? this.state.detail.ticket_init_name
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "70%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Email Pengadu</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <Link
                          to="#"
                          onClick={(e) => {
                            window.location = `mailto:${this.state.detail.ticket_init_email}`;
                            e.preventDefault();
                          }}
                        >
                          {this.state.detail.ticket_init_email !== ""
                            ? this.state.detail.ticket_init_email
                            : "-"}
                        </Link>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "25%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Subjek</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.ticket_subject !== ""
                            ? this.state.detail.ticket_subject
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "35%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Layanan</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.ticket_service !== ""
                            ? this.state.detail.ticket_service
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "35%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="ticket_priority"
                      className="col-md-3 col-form-label"
                    >
                      Prioritas
                    </label>
                    <Col md="9">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={false}
                        name="ticket_priority"
                        options={[
                          { label: "Pilih prioritas...", value: 0 },
                          { label: "Low", value: 1 },
                          { label: "Medium", value: 2 },
                          { label: "High", value: 3 },
                          { label: "Critical", value: 4 },
                        ]}
                        styles={this.colourStyles}
                        id="ticket_priority"
                        onChange={(e) => {
                          this.handleInputChange(e, true);
                        }}
                      />
                    </Col>
                  </Row>
                  {/* <Row className="mb-3">
                    <label className="col-md-3">Prioritas</label>
                    <Col md="9" style={{ textTransform: "uppercase" }}>
                      {this.state.detail ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: ".5rem",
                          }}
                        >
                          <Icon
                            icon="gg:overflow"
                            className={`text-${this.state.detail.ticket_priority_color}`}
                          />
                          <span
                            className={`text-${this.state.detail.ticket_priority_color}`}
                          >
                            <strong>
                              {this.state.detail.ticket_priority !== ""
                                ? this.state.detail.ticket_priority_text
                                : "-"}
                            </strong>
                          </span>
                        </div>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "20%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row> */}
                  <Row className="mb-3">
                    <label className="col-md-3">Pesan</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <div
                          className="container rounded py-3"
                          style={{ backgroundColor: "#DEE9F477" }}
                        >
                          <h5>{this.state.detail.ticket_init_name}</h5>
                          <hr className="my-1" />
                          <p>
                            {this.state.detail.ticket_content !== ""
                              ? this.state.detail.ticket_content
                              : "-"}
                          </p>
                          <p
                            className="p-0 m-0"
                            style={{ fontWeight: "500", color: "#999" }}
                          >
                            {this.state.detail.created_at ? (
                              <ReactTimeAgo
                                date={new Date(this.state.detail.created_at)}
                                locale="id-ID"
                              />
                            ) : null}
                          </p>
                        </div>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "90%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Balasan</label>
                    <Col md="9">
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column", rowGap: "1rem" }}
                      >
                        {this.state.detail.ticket_replies ? (
                          this.state.detail.ticket_replies.length > 0 ? (
                            this.state.detail.ticket_replies.map((reply) => {
                              return (
                                <div
                                  className="container rounded py-3"
                                  style={{ backgroundColor: "#F4E4E977" }}
                                >
                                  <h5>{reply.ticket_reply_sender_name}</h5>
                                  <hr className="my-1" />
                                  <p>{reply.ticket_reply_content}</p>
                                  <p
                                    className="p-0 m-0"
                                    style={{ fontWeight: "500", color: "#999" }}
                                  >
                                    <ReactTimeAgo
                                      date={new Date(reply.created_at)}
                                      locale="id-ID"
                                    />
                                  </p>
                                </div>
                              );
                            })
                          ) : (
                            <span>
                              <i>Belum ada balasan.</i>
                            </span>
                          )
                        ) : (
                          <Shimmer>
                            <div style={{ width: "90%", height: 15 }}></div>
                          </Shimmer>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Balas Pesan</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.ticket_status !== 4 && (
                            <>
                              <AvForm
                                className="needs-validation"
                                onValidSubmit={(e, values) =>
                                  this.handlerOnSubmit(e, values)
                                }
                                onKeyPress={this.preventEnter}
                              >
                                <AvField
                                  name="ticket_reply_content"
                                  placeholder="Balas pesan..."
                                  type="textarea"
                                  errorMessage="Masukkan Keterangan"
                                  className="form-control"
                                  rows={6}
                                  id="ticket_reply_content"
                                  style={{ position: "relative" }}
                                  onChange={this.handleInputChange}
                                />
                                <Button
                                  color="primary"
                                  style={{
                                    position: "absolute",
                                    borderRadius: "50%",
                                    right: "1.75rem",
                                    bottom: "1rem",
                                    boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
                                  }}
                                  title="Balas Pesan"
                                  type="submit"
                                >
                                  <i className="uil-comment-redo"></i>
                                </Button>
                              </AvForm>
                            </>
                          )}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "68%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="3"></Col>
                    <Col md="9">
                      <Link to="helpdesk.html">
                        <Button color="success">
                          <i className="uil-arrow-left"></i> Kembali
                        </Button>
                      </Link>{" "}
                      {this.state.detail.ticket_status !== 4 && (
                        <Button
                          color="purple"
                          type="submit"
                          title="Tutup Ticket"
                          onClick={this.handlerOnCloseTicket}
                        >
                          <i className="uil-sign-out-alt"></i> Tutup
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />

        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menutup ticket ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Ticket berhasil ditutup"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirmClose}
          showLoading={this.state.loadingClose}
          showSuccess={this.state.successClose}
          showFailed={this.state.failedClose}
          response={this.confirmCloseTicket}
        />

        {/* {this.state.redirect ? <Redirect to="/admin/helpdesk.html" /> : null} */}
      </Fragment>
    );
  }
}

//export default IndexView
export default withRouter(IndexView);
