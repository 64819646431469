import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PdfContent from "./Components/PdfContent"
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container, Row
} from "reactstrap";
import { accessRole } from "../../../helpers/access_role";
import TableComponent from "./Components/TableComponent";
import "./style.scss";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import logoSm from "../../../assets/images/logo-full.png"

const IndexView = (props) => {
  const doc = new jsPDF({
    orientation: "l",
    format: "a4",
    unit: "px",
  });
  const activeRef = useRef();
  const inactiveRef = useRef();

  const [agendasCount, setAgendasCount] = useState(0);
  const [attendances, setAttendances] = useState([]);

  const reloadInactiveRef = () => inactiveRef.current.setTokenAPI();
  
  function setCountCb(res, type) {
      setAgendasCount(res);
  }
  
  const renderTableView = () => {
    return (
      <>
        <TableComponent
          cb={setCountCb}
          ref={activeRef}
          generatePdf={generatePdf}
          handleInactiveSuccess={reloadInactiveRef}
        />
      </>
    );
  };

  function drawCheckbox(x, y, checked) {
    doc.rect(x, y, 6, 6); // Draw the checkbox
    if (checked) {
      doc.line(x, y + 5, x + 2, y + 8); // Draw the checkmark
      doc.line(x + 2, y + 8, x + 8, y + 2); // Draw the checkmark
    }
  }

  const generatePdf = (meeting_registration) => {
    // Create a new page in the PDF
    const attendancesData = meeting_registration.attendances;
    setAttendances(attendancesData)

    const pageWidth = doc.internal.pageSize.getWidth();

    // Set border color
    doc.setDrawColor(0); // Black

    doc.addImage(logoSm, 'JPEG', 35, 25,50, 30);

    doc.setFontSize(8);

    doc.roundedRect(pageWidth - 80,30, doc.getStringUnitWidth("Form - 00") + 30, 15, 1,1);
    doc.text("Form - 00", pageWidth - 80 + 5, 40);
    
    var width_start = 210
    drawCheckbox(200, 45, false); 
    doc.text("Rapat", width_start, 50);

    width_start += doc.getStringUnitWidth("Rapat") + 30;
    drawCheckbox(width_start, 45, false); 
    doc.text("Pelantikan", width_start + 10 , 50);

    width_start += doc.getStringUnitWidth("Pelantikan") + 40;
    drawCheckbox(width_start, 45, false); 
    doc.text("Briefing", width_start + 10, 50);

    width_start += doc.getStringUnitWidth("Briefing") + 40;
    drawCheckbox(width_start, 45, false); 
    doc.text("Sosialisasi", width_start + 10, 50);

    width_start += doc.getStringUnitWidth("Sosialisasi") + 40;
    drawCheckbox(width_start, 45, false); 
    doc.text("Lain-lain, ..................", width_start + 10, 50);

    doc.setFontSize(14);
    const text = 'Daftar Hadir';
    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const xPosition = (pageWidth - textWidth) / 2;
    doc.text(text, xPosition, 35);

    doc.setFontSize(10);
    doc.text("Kegiatan", 70, 70);
    doc.text(": "+ meeting_registration.meeting_registration_name , 120, 70);
    doc.text("Tanggal", 70, 80);
    doc.text(": "+ moment(meeting_registration.meeting_registration_date).format('LL'), 120, 80);
    doc.text("Pukul", 70, 90);
    doc.text(": "+ moment(meeting_registration.meeting_registration_start_time, "h:mm").format('HH:mm') + " - " + moment(meeting_registration.meeting_registration_end_time, "h:mm").format('HH:mm'),120, 90);
    if (meeting_registration.meeting_registration_type == 2) { //offline
      doc.text("Tempat", 70, 100);
      doc.text(": "+ meeting_registration.meeting_registration_place, 120, 100);

      // Draw a border around a rectangle
      doc.roundedRect(30, 20, pageWidth - 60, 90, 5,5);
    } else {
      // Draw a border around a rectangle
      doc.roundedRect(30, 20, pageWidth - 60, 80, 5, 5);
    }

    // Define data for the table
    const tableData = [
      ['No', 'Nama', 'NIP', 'Instansi', 'Telepon', 'Email'],
      ...attendancesData.map((attendance, index) => [
        (index + 1),
        attendance.meeting_registration_attendance_name || '-',
        attendance.meeting_registration_attendance_nip || '-',
        attendance.meeting_registration_attendance_company || '-',
        attendance.meeting_registration_attendance_no_telp || '-',
        attendance.meeting_registration_attendance_email || '-',
      ]),
    ];

    var startHeightAfterTable = meeting_registration.meeting_registration_type == 2 ? 120 : 110

    doc.autoTable({
      startY: meeting_registration.meeting_registration_type == 2 ? 120 : 110,
      head: [tableData[0]],
      body: tableData.slice(1),
      theme: 'grid',
      headStyles: { fillColor: [150, 150, 150] },
      columnStyles: { 0: { cellWidth: 'auto' }, 1: { cellWidth: 'auto' }, 2: { cellWidth: 'auto' }, 3: { cellWidth: 'auto' }, 4: { cellWidth: 'auto' }, 5: { cellWidth: 'auto' } },
      didDrawPage: (d) => {startHeightAfterTable = d.cursor.y},
    });

    doc.text("Penyelenggara : ", 30, startHeightAfterTable + 20);

    doc.rect(pageWidth - 120,startHeightAfterTable + 15, 90, 10);
    doc.rect(pageWidth - 212,startHeightAfterTable + 15, 90, 10);
    doc.rect(pageWidth - 120,startHeightAfterTable + 27, 90, 30);
    doc.rect(pageWidth - 212,startHeightAfterTable + 27, 90, 30);

    doc.text("Diketahui Oleh:", pageWidth - 100 , startHeightAfterTable + 22);
    doc.text("Dibuat Oleh:", pageWidth - 190 , startHeightAfterTable + 22);
    doc.text("(.......................................)", pageWidth - 118, startHeightAfterTable + 55);
    doc.text("(.......................................)", pageWidth - 210, startHeightAfterTable + 55);
    
    doc.line(0, startHeightAfterTable + 60, pageWidth, startHeightAfterTable + 60);
    
    doc.save('daftar_hadir.pdf');
  }

  const renderView = () => {
    return renderTableView();
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="d-flex" style={{ columnGap: "1rem" }}>
                    {accessRole("create") ? (
                      <Link to="meeting_registration.html?create">
                        <Button color="primary">Registrasi Rapat</Button>
                      </Link>
                    ) : null}
                  </div>
                </div>
                <CardTitle>Registrasi Rapat</CardTitle>
                <CardSubtitle className="mb-3">Data - data registrasi rapat</CardSubtitle>
                <hr />
                {renderView()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default IndexView;
